import '@khmyznikov/pwa-install';

// Create the pwa-install element
const pwaInstall = document.createElement('pwa-install') as any;

// Set the attributes
pwaInstall.setAttribute('install-description', 'This site includes app functionality. Install it on your device for an enhanced experience and easier access.');
pwaInstall.setAttribute('disable-screenshots', 'true');

pwaInstall.setAttribute('manifest-url', '/manifest.json');
pwaInstall.setAttribute('name', 'Fentrica');
pwaInstall.setAttribute('description', 'All-in-one Building Digitalisation Platform.');
pwaInstall.setAttribute('icon', '/img/apple-touch-icon-152x152.png');

// Insert the pwa-install element into the DOM
document.body.appendChild(pwaInstall);

console.info(
  'pwa-install element created.',
  'isDialogHidden?',
  pwaInstall.isDialogHidden,
  'isInstallAvailable?',
  pwaInstall.isInstallAvailable,
  'isRelatedAppsInstalled?',
  pwaInstall.isRelatedAppsInstalled,
  'isUnderStandaloneMode?',
  pwaInstall.isUnderStandaloneMode,
  'isAppleMobilePlatform?',
  pwaInstall.isAppleMobilePlatform,
  'isAppleDesktopPlatform?',
  pwaInstall.isAppleDesktopPlatform,
  'isAppleDesktopPlatform?',
  pwaInstall.isAppleDesktopPlatform,
);
