/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import { register } from 'register-service-worker';

export default register('/service-worker.js', {
  ready() {
    console.log(
      'App is using service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
    );
  },
  registered() {
    console.log('Service worker has been registered.');
  },
  cached() {
    console.log('Content has been cached for offline use.');
  },
  updatefound() {
    console.log('New content is downloading.');
  },
  updated(registration: ServiceWorkerRegistration) {
    console.log('New content is available; reloading the page...', registration.active);
    // eslint-disable-next-line no-restricted-globals, no-alert
    // const confirmationResult = confirm('New content found! Do you want to reload the app?');
    window.location.reload();
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.');
  },
  error(error) {
    console.error('Error during service worker registration:', error);
  },
});
