import { createI18n } from 'vue-i18n';

import messages from '../config/translations';

export default createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'et', // set fallback locale
  messages, // set locale messages
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  // If you need to specify other options, you can set other options
  // ... https://vue-i18n.intlify.dev/guide/#javascript
});
