import 'firebase/compat/messaging';

import firebase from 'firebase/compat/app';

import config from '@/config/firebase.config';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { vapidKey, ...firebaseConfig } = config;

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export interface LarvaNotificationPayloadData {
  id: string,
  code: string,
  logId: string,
  date: string,
  qualify: string,
  logLevel: string,
  createdAt: string,
  commentId?: string;
  taskId?: string;
  ticketId?: string;
  alarmId?: string;
  propertyId?: string;
  orgId?: string;
  accessGroupUserId?: string;
  accessGroupLicensePlateId?: string;
  accessGroupCardId?: string;
  orgRoleId?: string;
  propertyRoleId?: string;
  unitId?: string;
  externalPartnerId?: string;
  //
  commentContent?: string;
  taskNumber?: string;
  taskName?: string;
  ticketNumber?: string;
  ticketTitle?: string;
  unitName?: string;
  externalPartnerFirstname?: string;
  externalPartnerLastname?: string;
  externalPartnerEmail?: string;
  propertyName?: string;
  accessGroupUserFirstname?: string;
  accessGroupUserLastname?: string;
  accessGroupUserEmail?: string;
  accessGroupCardFirstname?: string;
  accessGroupCardLastname?: string;
  accessGroupCardEmail?: string;
  accessGroupLicensePlateFirstname?: string;
  accessGroupLicensePlateLastname?: string;
  accessGroupLicensePlatePlate?: string;
  orgName?: string;
  ruleActionName?: string;
  alarmPriority?: string,
  alarmClass?: string,
  alarmSource?: string,
  alarmState?: string,
  userFirstname?: string,
  userLastname?: string,
  userEmail?: string,
  propertyRoleFirstname?: string,
  propertyRoleLastname?: string,
  propertyRoleEmail?: string,
  // user!
}

export type Messaging = firebase.messaging.Messaging;
export type MessagePayload = firebase.messaging.MessagePayload;
export type Unsubscribe = () => void;

export default firebase.messaging();
