// default theme styles
import 'primeicons/primeicons.css';

import { EventBus } from '@primeuix/utils';
import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura'; // use Aura as a base theme.
import BadgeDirective from 'primevue/badgedirective';
import PrimeVue from 'primevue/config';
// services
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';
//
import Tooltip from 'primevue/tooltip';

/**
 * Generates a set of color values for a given color name, ranging from 50 to 950 in increments of 50.
 * The generated colors are returned as an object, where the keys are the color names (e.g. 'blue-50', 'blue-100', etc.)
 * and the values are the corresponding CSS variable names (e.g. 'var(--blue-50)', 'var(--blue-100)', etc.).
 * those values are defined in theme.scss
 */
function generateColor(color: string) {
  const ret: Record<number, string> = {};
  for (let i = 50; i <= 950; i += 50) {
    const colorValue = `var(--${color}-${i})`;
    // eslint-disable-next-line no-param-reassign
    ret[i] = colorValue;
  }
  return ret;
}

const Theme = definePreset(Aura, {
  // Your customizations, see the following sections for examples
  primitive: { // link primevue colors to our global colors
    blue: generateColor('blue'),
    green: generateColor('green'),
    yellow: generateColor('yellow'),
    orange: generateColor('orange'),
    red: generateColor('red'),
  },
  semantic: {
    primary: generateColor('primary'),
    colorScheme: {
      light: {
        surface: {
          ground: 'var(--surface-ground)',
          0: 'var(--surface-0)',
          ...generateColor('surface'),
        },
        content: {
          background: 'var(--surface-a)',
          hoverBackground: 'var(--surface-a)',
          borderColor: 'var(--surface-a)',
          color: 'var(--text-color)',
          hoverColor: 'var(--text-color)',
        },
        highlight: {
          background: 'var(--surface-100)',
          focusBackground: 'var(--surface-100)',
          color: 'var(--primary-500)',
          focusColor: 'var(--primary-500)',
        },
      },
    },
  },
  components: {
    inplace: {
      padding: '0',
    },
    toast: {
      root: {
        width: '26rem',
      },
      colorScheme: {
        light: {
          success: {
            background: 'color-mix(in srgb, var(--lar-color-success), transparent 5%)',
            color: 'var(--lar-color-success-contrast)',
            detailColor: 'var(--lar-color-success-contrast)',
            borderColor: 'var(--lar-color-success)',
            closeButton: {
              focusRing: {
                color: 'transparent',
              },
            },
          },
          error: {
            background: 'color-mix(in srgb, var(--lar-color-danger), transparent 5%)',
            color: 'var(--lar-color-danger-contrast)',
            detailColor: 'var(--lar-color-danger-contrast)',
            borderColor: 'var(--lar-color-danger)',
            closeButton: {
              focusRing: {
                color: 'transparent',
              },
            },
          },
          warn: {
            background: 'color-mix(in srgb, var(--lar-color-warning), transparent 5%)',
            color: 'var(--lar-color-warning-contrast)',
            detailColor: 'var(--lar-color-warning-contrast)',
            borderColor: 'var(--lar-color-warning)',
            closeButton: {
              focusRing: {
                color: 'transparent',
              },
            },
          },
          info: {
            background: 'color-mix(in srgb, var(--lar-color-tertiary), transparent 5%)',
            color: 'var(--lar-color-tertiary-contrast)',
            detailColor: 'var(--lar-color-tertiary-contrast)',
            borderColor: 'var(--lar-color-tertiary)',
            closeButton: {
              focusRing: {
                color: 'transparent',
              },
            },
          },
        },
      },
    },
    toolbar: {
      root: {
        background: 'var(--surface-ground)',
        borderColor: 'var(--surface-border)',
      },
    },
    menu: {
      root: {
        borderColor: 'var(--surface-border)',
      },
    },
    button: {
      colorScheme: {
        light: {
          text: {
            primary: {
              hoverBackground: 'var(--surface-100)',
              activeBackground: 'var(--surface-100)',
            },
            secondary: {
              hoverBackground: 'var(--surface-100)',
              activeBackground: 'var(--surface-100)',
            },
            success: {
              hoverBackground: 'var(--surface-100)',
              activeBackground: 'var(--surface-100)',
            },
            info: {
              hoverBackground: 'var(--surface-100)',
              activeBackground: 'var(--surface-100)',
            },
            warn: {
              hoverBackground: 'var(--surface-100)',
              activeBackground: 'var(--surface-100)',
            },
            help: {
              hoverBackground: 'var(--surface-100)',
              activeBackground: 'var(--surface-100)',
            },
            danger: {
              hoverBackground: 'var(--surface-100)',
              activeBackground: 'var(--surface-100)',
            },
            plain: {
              hoverBackground: 'var(--surface-100)',
              activeBackground: 'var(--surface-100)',
            },
          },
        },
      },
    },
    tree: {
      root: {
        padding: '0',
      },
    },
    datepicker: {
      colorScheme: {
        today: {
          background: 'var(--surface-100)',
          color: 'var(--surface-900)',
        },
      },
      date: {
        hoverBackground: 'var(--surface-100)',
        selectedColor: 'var(--lar-color-primary)',
        selectedBackground: 'var(--surface-100)',
      },
      selectMonth: {
        hoverBackground: 'var(--surface-100)',
        color: 'var(--lar-color-primary)',
        hoverColor: 'var(--surface-100)',
      },
      selectYear: {
        hoverBackground: 'var(--surface-100)',
        color: 'var(--lar-color-primary)',
        hoverColor: 'var(--surface-100)',
      },
    },
    datatable: {
      bodyCell: {
        borderColor: 'var(--surface-100)',
      },
    },
    card: { // @see primevue/themes/aura/card
      body: {
        padding: 0,
      },
    },
    avatar: {
      root: {
        background: 'var(--surface-100)',
      },
      group: {
        offset: '-0.5rem',
      },
    },
    timeline: {
      eventMarker: {
        borderColor: 'var(--surface-900)',
        content: {
          background: 'var(--surface-900)',
        },
      },
      eventConnector: {
        color: 'var(--surface-100)',
      },
    },
    breadcrumb: {
      root: {
        padding: '0.5rem 0',
      },
    },
    accordion: {
      content: {
        padding: '0 0.5rem',
        background: 'transparent',
      },
    },
    message: {
      colorScheme: {
        light: {
          primary: {
            background: 'var(--lar-color-dark)',
            color: 'var(--lar-color-dark-contrast)',
          },
          warn: {
            background: 'var(--lar-color-warning)',
            color: 'var(--lar-color-warning-contrast)',
          },
          danger: {
            background: 'var(--lar-color-danger)',
            color: 'var(--lar-color-danger-contrast)',
          },
          error: {
            background: 'var(--lar-color-danger)',
            color: 'var(--lar-color-danger-contrast)',
          },
          success: {
            background: 'var(--lar-color-success)',
            color: 'var(--lar-color-success-contrast)',
          },
          info: {
            background: 'var(--lar-color-tertiary)',
            color: 'var(--lar-color-tertiary-contrast)',
          },
        },
      },
    },
    tag: {
      root: {
        padding: '0.2rem 0.4rem',
        fontSize: '0.9rem',
        fontWeight: 300,
      },
      colorScheme: {
        light: {
          primary: {
            background: 'var(--lar-color-dark)',
            color: 'var(--lar-color-dark-contrast)',
          },
          warn: {
            background: 'var(--lar-color-warning)',
            color: 'var(--lar-color-warning-contrast)',
          },
          danger: {
            background: 'var(--lar-color-danger)',
            color: 'var(--lar-color-danger-contrast)',
          },
          success: {
            background: 'var(--lar-color-success)',
            color: 'var(--lar-color-success-contrast)',
          },
          info: {
            background: 'var(--lar-color-tertiary)',
            color: 'var(--lar-color-tertiary-contrast)',
          },
        },
      },
    },
  },
});

// eslint-disable-next-line import/prefer-default-export
export {
  BadgeDirective,
  ConfirmationService,
  DialogService,
  PrimeVue,
  Theme,
  ToastService,
  Tooltip,
};

export const eventBus = EventBus();
